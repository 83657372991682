import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { observable, action, computed, makeObservable } from "mobx";
import { PackageConfig } from "stores/wizard/config";
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { Travelers } from "src/components/shared/TravelersField/typings";
import { DateState, TravelersState } from "stores/wizard/state/global";
import { dateState } from "stores/wizard/state/global/date";
import { locationState } from "src/stores/wizard/state/global/location";
import { Location } from "src/stores/wizard/state/global/location/typings";
import { setHotelPartialStayDate } from "stores/wizard/state/global/date/dateUtils";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { FlightLegState } from "../flight/MultiLegFlights/typings";
import { TypeaheadSelectionProps } from "src/components/flexComponents/WizardHotelPWA/typings";
import { formatDate } from "bernie-l10n";
import { PackageExperiment } from "src/components/flexComponents/WizardPackagePWA/typings";
import { locKeys } from "src/components/flexComponents/WizardPackagePWA/l10n";
import { FlightTypeCodeMap } from "../flight/typings";
import { isVariantEnabled } from "src/components/utility/experiment";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { FlightType, PackagesSubLobs } from "../typings";
import { ClassCodes } from "src/components/shared/PreferredClassInput/PreferredClassInput";
import { PreferredAirline } from "src/components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { travelersStateOnConfig } from "../global/travelers/travelersState";
import { DestinationModifier } from "stores/wizard/state/DestinationModifier";

export class PackageWizardState extends DestinationModifier implements LOBState {
  private globalState: GlobalWizardState;

  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];

  public localDateFormat: string;

  public buildMultiCityFlightLegUrl = (legIndex: number) => {
    const rawFormatForSubmission = this.config.date.ISOSubmissionSupport
      ? this.config.date.format
      : this.localDateFormat;

    const legOrigin = this.listOfAdditionalLegs[legIndex].location.origin;
    const legDestination = this.listOfAdditionalLegs[legIndex].location.destination;
    const startDate = formatDate(this.listOfAdditionalLegs[legIndex].date.start, {
      raw: rawFormatForSubmission,
    });

    return `orig:${legOrigin.metaData.ttla};origId:${legOrigin.metaData.regionId};dest:${legDestination.metaData.ttla};destId:${legDestination.metaData.regionId};departure:${startDate}`;
  };

  public updateLegLocationOrigin(selection: TypeaheadSelection, index: number) {
    this.updateLegLocation(selection, index, this.listOfAdditionalLegs[index].location.origin);
  }

  public updateLegLocationDestination(selection: TypeaheadSelection, index: number) {
    this.updateLegLocation(selection, index, this.listOfAdditionalLegs[index].location.destination);
  }

  public updateLegLocation(selection: TypeaheadSelection, index: number, locationToUpdate: Location) {
    const { term, data } = selection as TypeaheadSelectionProps;
    locationToUpdate.value = term;
    locationToUpdate.metaData.regionId = data?.regionId;
    locationToUpdate.metaData.ttla = data?.hierarchyInfo?.airport?.airportCode;
    this.multiCityFlightLegURL[index] = this.buildMultiCityFlightLegUrl(index);
  }

  public swapOriginAndDestinationLocations(index: number) {
    const legToUpdate = this.listOfAdditionalLegs[index];
    const originalOrigin = legToUpdate.location.origin;
    const originalDestination = legToUpdate.location.destination;
    legToUpdate.location.origin = originalDestination;
    legToUpdate.location.destination = originalOrigin;
    this.multiCityFlightLegURL[index] = this.buildMultiCityFlightLegUrl(index);
  }

  public updateLegDate = (start: Date, end: Date, index: number) => {
    const legToUpdate = this.listOfAdditionalLegs[index];
    const previousDate = new Date(legToUpdate.date.start);
    legToUpdate.date.start = start;
    legToUpdate.date.end = end;
    this.multiCityFlightLegURL[index] = this.buildMultiCityFlightLegUrl(index);
    this.updateLegsDates(previousDate, start, end, index);
    this.updatePackageDates();
    this.validateDepatureDates();
  };

  private updateLegsDates(previousDate: Date, start: Date, end: Date, index: number) {
    const previousDepartureDate = this.newDateFrom(previousDate);
    for (let i = index; i < this.listOfAdditionalLegs.length; i++) {
      const legDateState = this.listOfAdditionalLegs[i].date;
      const legDate = this.newDateFrom(legDateState.start);
      if (legDate.getTime() === previousDepartureDate.getTime()) {
        this.listOfAdditionalLegs[i].date.start = start;
        this.listOfAdditionalLegs[i].date.end = end;

        this.multiCityFlightLegURL[i] = this.buildMultiCityFlightLegUrl(i);
      }
    }
  }

  public get location() {
    return this.globalState.location;
  }

  public get hotelLocation() {
    return this.globalState.hotelLocation;
  }

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  public config: PackageConfig;
  public staySomewhereElse = false;
  public driveSomewhereElse = false;
  public partialStay = false;
  public onewayFlight = false;
  public experimentNewPackageOnewayFlightType: PackageExperiment;
  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public errorSummaryRef: React.RefObject<HTMLInputElement> | null;
  public numberOfErrors = 0;
  public isHotelCarFormValid = true;
  public isFlightCarFormValid = true;
  public isFlightHotelCarFormValid = true;
  public isFlightHotelDefaultFormValid = true;
  public isFlightHotelOneWayFormValid = true;
  public isFlightHotelMultiFormValid = true;

  public get flightClassCode() {
    return this.globalState.flightClassCode;
  }

  public get flightAirline() {
    return this.globalState.flightAirline;
  }

  public get flightTypeCode() {
    return this.globalState.flightTypeCode;
  }

  public subLOBState = "";
  public subLOBFlightTypeState: FlightType = FlightType.ROUND_TRIP;
  public listOfAdditionalLegs: FlightLegState[];
  public multiCityFlightLegURL: string[];
  public partialStayDate: DateState;
  public packageDates: DateState;
  public moduleName = "";
  public trackingEnabled = true;

  public get date() {
    return this.packageDates;
  }

  public packageTravelers: TravelersState;

  public get travelers() {
    return this.packageTravelers;
  }

  public get hotelPackageTravelersMetadata() {
    return travelersMetadata(this.travelers.hotelPackage);
  }

  public get nonHotelTravelersMetadata() {
    return travelersMetadata(this.travelers.nonHotel);
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  //validations
  public originInvalidKey: string | "";

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  findFocusElement = (keyword: string) =>
    this.wizardInputsArray.find((element) => element.current !== null && element.current.outerHTML.includes(keyword));

  public validateOriginField = () => {
    if (this.subLOBState !== PackagesSubLobs.HOTEL_CAR && !this.globalState.validateOriginField()) {
      this.originInvalidKey = this.config.location.origin.invalidLocationMessageKey;
      this.errorInputRef = this.findFocusElement("origin")!;

      return false;
    }

    this.originInvalidKey = "";

    return true;
  };

  public destinationInvalidKey: string | "";

  public validateDestinationField = () =>
    this.validateDestinationFieldHasValue() && this.validateOriginDifferentFromDestination();

  public validateDestinationFieldHasValue = () => {
    if (!this.globalState.validateDestinationField()) {
      this.destinationInvalidKey = this.config.location.destination.invalidLocationMessageKey;
      // if the origin field is valid or if subLob does not have origin field I want to focus the invalid destination
      if (this.globalState.validateOriginField() || this.subLOBState === PackagesSubLobs.HOTEL_CAR) {
        if (!this.errorInputRef) {
          this.errorInputRef = this.findFocusElement("destination")!;
        }
      }

      return false;
    }
    this.destinationInvalidKey = "";

    return true;
  };

  public validateOriginDifferentFromDestination = () => {
    if (!this.globalState.validateOriginDifferentFromDestination() && this.subLOBState !== PackagesSubLobs.HOTEL_CAR) {
      this.destinationInvalidKey = this.config.location.destination.invalidOriginSameAsDestinationKey!;
      if (!this.errorInputRef) {
        this.errorInputRef = this.findFocusElement("destination")!;
      }

      return false;
    }
    this.destinationInvalidKey = "";

    return true;
  };

  public hotelTravelersInvalidKey: string | "";
  public nonHotelTravelersInvalidKey: string | "";

  public validateLessThanNTravelers = () => {
    let isValid = false;
    switch (this.subLOBState) {
      case PackagesSubLobs.FLIGHT_CAR:
        isValid = this.globalState.validateLessThanNTravelers(
          this.nonHotelTravelersMetadata,
          this.config.subLOB.flightCar.travelers
        );
        if (!isValid) {
          this.nonHotelTravelersInvalidKey = this.config.subLOB.flightCar.travelers.invalidLessThanNTravelersMessageToken;

          return false;
        }
        break;
      case PackagesSubLobs.HOTEL_CAR:
        isValid = this.globalState.validateLessThanNTravelers(
          this.hotelPackageTravelersMetadata,
          this.config.subLOB.hotelCar.travelers
        );
        if (!isValid) {
          this.hotelTravelersInvalidKey = this.config.subLOB.hotelCar.travelers.invalidLessThanNTravelersMessageToken;

          return false;
        }
        break;
      default:
        isValid = this.globalState.validateLessThanNTravelers(
          this.hotelPackageTravelersMetadata,
          this.config.travelers
        );
        if (!isValid) {
          this.hotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

          return false;
        }
    }

    return true;
  };

  public validateUnattendedInfantInLap = () => {
    let isValid = false;
    switch (this.subLOBState) {
      case PackagesSubLobs.FLIGHT_CAR:
        isValid = this.globalState.validateUnattendedInfantInLap(
          this.nonHotelTravelersMetadata,
          this.config.subLOB.flightCar.travelers,
          this.travelers.nonHotel.infantSeating
        );
        if (!isValid) {
          this.nonHotelTravelersInvalidKey = this.config.subLOB.flightCar.travelers.invalidUnattendedInfantOnLapMessageToken;

          return false;
        }
        break;
      case PackagesSubLobs.HOTEL_CAR:
        isValid = this.globalState.validateUnattendedInfantInLap(
          this.hotelPackageTravelersMetadata,
          this.config.subLOB.hotelCar.travelers,
          this.travelers.hotel.infantSeating
        );
        if (!isValid) {
          this.hotelTravelersInvalidKey = this.config.subLOB.hotelCar.travelers.invalidUnattendedInfantOnLapMessageToken;

          return false;
        }
        break;
      default:
        isValid = this.globalState.validateUnattendedInfantInLap(
          this.hotelPackageTravelersMetadata,
          this.config.travelers,
          this.travelers.hotel.infantSeating
        );
        if (!isValid) {
          this.hotelTravelersInvalidKey = this.config.travelers.invalidUnattendedInfantOnLapMessageToken;

          return false;
        }
    }

    return true;
  };

  public validateInfantsPerTraveler = () => {
    let isValid = false;
    switch (this.subLOBState) {
      case PackagesSubLobs.FLIGHT_CAR:
        isValid = this.globalState.validateInfantsPerTraveler(
          this.nonHotelTravelersMetadata,
          this.config.subLOB.flightCar.travelers,
          this.travelers.nonHotel.infantSeating
        );
        if (!isValid) {
          this.nonHotelTravelersInvalidKey = this.config.travelers.invalidNumberOfInfantPerTravelerMessageToken!;

          return false;
        }
        break;
      case PackagesSubLobs.HOTEL_CAR:
        isValid = this.globalState.validateUnattendedInfantInLap(
          this.hotelPackageTravelersMetadata,
          this.config.subLOB.hotelCar.travelers,
          this.travelers.hotel.infantSeating
        );
        if (!isValid) {
          this.hotelTravelersInvalidKey = this.config.subLOB.hotelCar.travelers.invalidUnattendedInfantOnLapMessageToken;

          return false;
        }
        break;
      default:
        isValid = this.globalState.validateInfantsPerTraveler(
          this.hotelPackageTravelersMetadata,
          this.config.travelers,
          this.travelers.hotel.infantSeating
        );
        if (!isValid) {
          this.hotelTravelersInvalidKey = this.config.travelers.invalidNumberOfInfantPerTravelerMessageToken!;

          return false;
        }
    }

    return true;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    if (!this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    if (!this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() &&
    this.validateUnattendedInfantInLap() &&
    this.validateChildrenFields() &&
    this.validateInfantFields();

  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";

  public validateMaxDateRange = () => {
    switch (this.subLOBState) {
      case PackagesSubLobs.HOTEL_CAR:
        if (
          !this.globalState.validateMaxDateRange(
            this.date.start,
            this.date.end,
            this.config.subLOB.hotelCar.date.maxDaysRange
          )
        ) {
          this.dateEndInvalidKey = this.config.subLOB.hotelCar.date.invalidMaxDatesRangeMessageToken;
          if (!this.errorInputRef) {
            this.errorInputRef = this.findFocusElement("d2")!;
          }

          return false;
        }
        break;
      default:
        if (
          this.isRoundtripFlight() &&
          !this.globalState.validateMaxDateRange(this.date.start, this.date.end, this.config.date.maxDaysRange)
        ) {
          this.dateEndInvalidKey = this.config.date.invalidMaxDatesRangeMessageToken;
          if (!this.errorInputRef) {
            this.errorInputRef = this.findFocusElement("d2")!;
          }

          return false;
        }
    }

    return true;
  };

  public validateEndDateField = () => this.validateMaxDateRange();

  public validateEndDateBeforeStartDateFields = () => {
    const departingDate = this.date.start;
    const returningDate = this.date.end;

    if (this.globalState.validateEndDateBeforeStartDateFields(departingDate, returningDate)) {
      this.dateEndInvalidKey = this.globalState.config.date.invalidDateRangeEndBeforeStart ?? "";
      if (!this.errorInputRef) {
        this.errorInputRef = this.findFocusElement("d2") ?? null;
      }

      return false;
    }

    return true;
  };

  public partialStayStartDateInvalidKey = "";

  public validatePartialStayStartDateWithinRange = () => {
    if (!this.isPartialStay()) {
      return true;
    }

    switch (this.subLOBState) {
      case "":
      case PackagesSubLobs.FLIGHT_HOTEL:
      case PackagesSubLobs.FLIGHT_HOTEL_CAR:
        let startDate = this.date.start;
        let endDate = this.date.end;
        if (this.isMulticityFlight()) {
          const legsDates = this.getLegsDates();
          startDate = legsDates.shift() || startDate;
          endDate = legsDates.length >= 1 ? legsDates.pop() || endDate : this.partialStayDate.end;
        } else if (this.isOnewayFlight()) {
          endDate = dateState(this.config.date.daysBookableInAdvance).end; // max days
        }

        if (!this.globalState.validatePartialDateWithinRange(startDate, endDate, this.partialStayDate.start)) {
          this.partialStayStartDateInvalidKey = this.retrieveConfigPartialDates().invalidStartDateOutOfRangeMessageToken;
          if (!this.errorInputRef) {
            this.errorInputRef = this.findFocusElement("d1")!;
          }

          return false;
        }

        return true;
      default:
        return true;
    }
  };

  public validatePartialStayStartDateField = () => this.validatePartialStayStartDateWithinRange();

  public partialStayEndDateInvalidKey = "";

  public validatePartialStayMaxDateRange = () => {
    switch (this.subLOBState) {
      case "":
      case PackagesSubLobs.FLIGHT_HOTEL:
      case PackagesSubLobs.FLIGHT_HOTEL_CAR:
        if (
          this.isPartialStay() &&
          !this.globalState.validateMaxDateRange(
            this.partialStayDate.start,
            this.partialStayDate.end,
            this.config.partialStayDate.maxDaysRange
          )
        ) {
          this.partialStayEndDateInvalidKey = this.config.partialStayDate.invalidMaxDatesRangeMessageToken;
          if (!this.errorInputRef) {
            this.errorInputRef = this.findFocusElement("d2")!;
          }

          return false;
        }

        return true;
      default:
        return true;
    }
  };

  public validatePartialStayEndDateWithinRange = () => {
    if (!this.isPartialStay()) {
      return true;
    }

    switch (this.subLOBState) {
      case "":
      case PackagesSubLobs.FLIGHT_HOTEL:
      case PackagesSubLobs.FLIGHT_HOTEL_CAR:
        let startDate = this.date.start;
        let endDate = this.date.end;
        if (this.isOnewayFlight()) {
          endDate = this.newDateFrom(this.partialStayDate.end);
          startDate = this.newDateFrom(this.date.start);
          if (startDate >= endDate) {
            this.partialStayEndDateInvalidKey = this.config.onewayFlight.partialStayDate.invalidEndDateMessageToken;

            return false;
          }
        } else if (this.isMulticityFlight()) {
          const legsDates = this.getLegsDates(); // all legs
          startDate = legsDates.shift() || startDate;
          endDate = legsDates.length >= 1 ? legsDates.pop() || endDate : this.partialStayDate.end;
        }

        if (!this.globalState.validatePartialDateWithinRange(startDate, endDate, this.partialStayDate.end)) {
          this.partialStayEndDateInvalidKey = this.retrieveConfigPartialDates().invalidEndDateOutOfRangeMessageToken;

          return false;
        }

        return true;
      default:
        return true;
    }
  };

  private retrieveConfigPartialDates() {
    if (this.isOnewayFlight()) {
      return this.config.onewayFlight.partialStayDate;
    }
    if (this.isMulticityFlight()) {
      return this.config.multiCityFlight.partialStayDate;
    }

    return this.config.partialStayDate;
  }

  public validatePartialStayEndDateField = () =>
    this.validatePartialStayEndDateWithinRange() && this.validatePartialStayMaxDateRange();

  public validateLobCombinationState = () => {
    return this.subLOBState !== "error";
  };

  public legsDateStartInvalidKey: string[];

  public validateDepatureDates = (): boolean => {
    if (this.isMulticityFlight()) {
      let previous: Date = this.newDateFrom(this.date.start);
      for (const [index, leg] of this.listOfAdditionalLegs.entries()) {
        if (!leg.location.origin.metaData.regionId && !leg.location.destination.metaData.regionId) {
          continue; // no validation if location is empty
        }
        const legDate = this.newDateFrom(leg.date.start);
        if (legDate.getTime() >= previous.getTime()) {
          previous = legDate;
          this.legsDateStartInvalidKey[index] = "";
        } else {
          this.legsDateStartInvalidKey[index] = locKeys.error.flightInvalidDepartureDate;
        }
      }

      return !this.legsDateStartInvalidKey.includes(locKeys.error.flightInvalidDepartureDate);
    }

    const maxNumberOfAdditionalLegs = this.config.multiCityFlight.maxNumberOfAdditionalLegs;
    this.legsDateStartInvalidKey = new Array<string>(maxNumberOfAdditionalLegs).fill("", 0, maxNumberOfAdditionalLegs);

    return true;
  };

  public hotelDestinationInvalidKey = "";

  public validateHotelDestination = (): boolean => {
    let validHotelDestination = true;

    if (!this.hotelLocation?.destination?.metaData?.regionId) {
      // fh + fssse + multicity/oneway
      if (this.isPackageFlightHotel() && this.isStaySomewhereElse() && !this.isRoundtripFlight()) {
        if (this.isOnewayFlight() && this.experimentNewPackageOnewayFlightType.variant1) {
          if (!this.errorInputRef) {
            this.errorInputRef = this.findFocusElement("destination")!;
          }

          validHotelDestination = false;
        }
      } else if (this.isPackageHotelCar() && this.driveSomewhereElse) {
        // hc + different pickup location
        if (!this.errorInputRef) {
          this.errorInputRef = this.findFocusElement("destination")!;
        }

        validHotelDestination = false;
      }
    }

    this.hotelDestinationInvalidKey = validHotelDestination ? "" : locKeys.error.staySomewhereElseInvalidDestination;

    return validHotelDestination;
  };

  public resetValidations = () => {
    this.originInvalidKey = "";
    this.destinationInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.hotelDestinationInvalidKey = "";
    this.partialStayStartDateInvalidKey = "";
    this.partialStayEndDateInvalidKey = "";
    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
    const maxNumberOfAdditionalLegs = this.config.multiCityFlight.maxNumberOfAdditionalLegs;
    this.legsDateStartInvalidKey = new Array<string>(maxNumberOfAdditionalLegs).fill("", 0, maxNumberOfAdditionalLegs);
  };

  public validateForm = () => {
    this.cleanErrorState();

    const validations: boolean[] = [
      this.validateOriginField(),
      this.validateDestinationField(),
      this.validateTravelersField(),
      this.validateEndDateField(),
      this.validateEndDateBeforeStartDateFields(),
      this.validateDepatureDates(),
      this.validateHotelDestination(),
      this.validatePartialStayStartDateField(),
      this.validatePartialStayEndDateField(),
      this.validateLobCombinationState(),
    ];

    validations.forEach((value) => !value && (this.numberOfErrors += 1));

    return !validations.includes(false);
  };

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updatePackageDates() {
    this.packageDates.start = this.date.start;

    if (this.isOnewayFlight()) {
      this.packageDates.end = this.partialStayDate.end;
    } else if (this.isMulticityFlight()) {
      const legsDates = this.getLegsDates();

      this.packageDates.end = legsDates.pop() || this.packageDates.end;
    } else {
      // roundtrip
      this.packageDates.end = this.date.end;
    }
  }

  private getLegsDates(): Date[] {
    const legsDates = new Array<Date>(this.date.start);
    if (this.listOfAdditionalLegs) {
      this.listOfAdditionalLegs.forEach((leg: FlightLegState) => {
        if (leg.location.origin.metaData.regionId && leg.location.destination.metaData.regionId) {
          legsDates.push(leg.date.start);
        }
      });
    }

    legsDates.sort((a: Date, b: Date) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    return legsDates;
  }

  public updateDateSelection = (start: Date, end: Date, multiLegIndex?: number) => {
    if (multiLegIndex !== undefined) {
      this.updateLegDate(start, end, multiLegIndex);
    } else {
      const previousDepatureDate = new Date(this.date.start);

      this.copyDepartureReturningDatesToHotel(start, end);
      this.date.start = start;
      this.date.end = end;

      this.dateStartInvalidKey = "";
      this.dateEndInvalidKey = "";
      this.partialStayStartDateInvalidKey = "";
      this.partialStayEndDateInvalidKey = "";

      // update additionals legs (if exist) when first row change.
      if (this.listOfAdditionalLegs && this.listOfAdditionalLegs.length > 0) {
        this.updateLegsDates(previousDepatureDate, start, end, 0);
      }
      this.updatePackageDates();
    }
  };

  public updatePartialStayDateSelection = (start: Date, end: Date) => {
    this.partialStayDate.start = start;
    this.partialStayDate.end = end;
    this.partialStayStartDateInvalidKey = "";
    this.partialStayEndDateInvalidKey = "";

    this.updatePackageDates();
  };

  public updateOriginSelection = (selection: TypeaheadSelection, index?: number) => {
    //in the case of a multi-city flight, update the individual leg
    if (index !== undefined) {
      this.updateLegLocationOrigin(selection, index);
    } else {
      this.globalState.updateOriginSelection(selection);
      this.originInvalidKey = "";
    }
  };

  public updateDestinationSelection = (selection: TypeaheadSelection, index?: number) => {
    if (index !== undefined) {
      this.updateLegLocationDestination(selection, index);
    } else {
      this.globalState.updateDestinationSelection(selection);
      this.destinationInvalidKey = "";

      // copy hotel destination if empty and fssse
      if (this.isStaySomewhereElse() && !this.globalState.hotelLocation.destination.metaData.regionId) {
        this.updateHotelDestinationSelection(selection);
      }
    }
  };

  public updateHotelDestinationSelection = (selection: TypeaheadSelection) => {
    this.globalState.updateHotelDestinationSelection(selection);
  };

  public copyDepartureReturningDatesToHotel(start: Date, end: Date) {
    if (this.isPartialStay()) {
      this.copyDepartureToHotelCheckIn(start);
      this.copyReturningToHotelCheckOut(end);
    }
  }

  private copyDepartureToHotelCheckIn(start: Date) {
    // copy flight dates to hotel dates if they are the same
    const previousStartDate = this.newDateFrom(this.date.start);
    const previousStartPartialStay = this.newDateFrom(this.partialStayDate.start);
    if (previousStartDate.getTime() === previousStartPartialStay.getTime()) {
      this.partialStayDate.start = start;
    }
  }

  private copyReturningToHotelCheckOut(end: Date) {
    const previousEndDate = this.newDateFrom(this.date.end);
    const previousEndPartialStay = this.newDateFrom(this.partialStayDate.end);
    if (previousEndDate.getTime() === previousEndPartialStay.getTime()) {
      this.partialStayDate.end = end;
    } else if (this.partialStayDate.end < this.partialStayDate.start) {
      const newEnd = new Date(this.partialStayDate.start);
      this.partialStayDate.end = new Date(newEnd.setDate(newEnd.getDate() + this.config.defaultLengthInDays));
    }
  }

  public swapLocations(index?: number) {
    if (index !== undefined) {
      this.swapOriginAndDestinationLocations(index);
    } else {
      this.globalState.swapOriginAndDestinationLocations();
    }
  }

  public togglePartialStay() {
    this.partialStay = !this.partialStay;
    setHotelPartialStayDate(this.date, this.partialStayDate, this.config.defaultLengthInDays);
  }

  public setFlightClass(flightClassCode: ClassCodes) {
    this.globalState.setFlightClass(flightClassCode);
  }

  public setFlightAirline(flightAirline: PreferredAirline) {
    this.globalState.setFlightAirline(flightAirline);
  }

  public setFlightType(flightTypeCode: FlightType) {
    this.updateSubLOBFlightTypeState(flightTypeCode);
    this.globalState.setFlightType(flightTypeCode);
  }

  public addALeg = () => {
    if (this.listOfAdditionalLegs.length < this.config.multiCityFlight.maxNumberOfAdditionalLegs) {
      let legDateState = {
        start: this.date.start,
        end: this.date.end,
      };

      if (this.listOfAdditionalLegs.length > 0) {
        legDateState = {
          start: this.listOfAdditionalLegs[this.listOfAdditionalLegs.length - 1].date.start,
          end: this.listOfAdditionalLegs[this.listOfAdditionalLegs.length - 1].date.end,
        };
      }

      this.listOfAdditionalLegs.push({ location: locationState(), date: legDateState });
      this.onewayFlight = false;
      this.setFlightType(FlightType.MULTI_CITY);
    }
  };

  public removeALeg = (index: number) => {
    if (index < this.listOfAdditionalLegs.length) {
      this.listOfAdditionalLegs.splice(index, 1);
    }
  };

  public updateSubLOBState(subLOBState: string) {
    this.subLOBState = subLOBState;
    this.updateEssAdapterConfigPackageType(subLOBState);
  }

  public updateSubLOBFlightTypeState(subLOBState: FlightType) {
    this.subLOBFlightTypeState = subLOBState;
    this.globalState.setFlightType(subLOBState);

    if (subLOBState === FlightType.MULTI_CITY && this.listOfAdditionalLegs.length === 0) {
      this.listOfAdditionalLegs = [
        {
          location: locationState(),
          date: this.globalState.getDateFromConfig(this.config.date),
        },
      ];
    }
  }

  public updateHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateHotelTravelersSelection(travelers, this.travelers);

    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
  };

  public updateNonHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateNonHotelTravelersSelection(travelers, this.travelers);

    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
  };

  public updateDestinationFromContext(
    value: string,
    regionID: string,
    countryCode: string,
    latLong: string,
    ttla: string,
    destinationID: string,
    hotelID: string
  ) {
    this.globalState.updateDestinationFromContext(value, regionID, countryCode, latLong, ttla, destinationID, hotelID);
    this.destinationInvalidKey = "";
  }

  public updateOnewayFlag(value: boolean) {
    this.onewayFlight = value;
  }

  public updateExperimentNewPackageVariant(context: ExtendedContextStore) {
    const onewayPackageExperimentVariant1 = isVariantEnabled(context, "MIT_PWA_PKGFLEXIBILITY_ONEWAYPKG", 1);
    this.experimentNewPackageOnewayFlightType = {
      control: !onewayPackageExperimentVariant1,
      variant1: onewayPackageExperimentVariant1,
    };
  }

  private newDateFrom(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public get flightTypeCodeForURL() {
    return FlightTypeCodeMap[this.flightTypeCode] || FlightTypeCodeMap.default;
  }

  public isPackageFlightHotel() {
    return this.subLOBState === PackagesSubLobs.FLIGHT_HOTEL;
  }

  public isOnewayFlight(): boolean {
    return this.flightTypeCode === FlightType.ONE_WAY || this.onewayFlight;
  }

  public isMulticityFlight(): boolean {
    return this.flightTypeCode === FlightType.MULTI_CITY;
  }

  public isRoundtripFlight(): boolean {
    return this.flightTypeCode === FlightType.ROUND_TRIP;
  }

  public isPackageHotelCar(): boolean {
    return this.subLOBState === PackagesSubLobs.HOTEL_CAR;
  }

  public isPartialStay(): boolean {
    return this.partialStay || this.isOnewayFlight() || this.isMulticityFlight();
  }

  public isStaySomewhereElse(): boolean {
    return this.staySomewhereElse || this.isOnewayFlight() || this.isMulticityFlight();
  }

  public submit = (event: React.FormEvent) => {
    const isValidForm = this.validateForm();
    if (!isValidForm) {
      event.preventDefault();
      this.updateInvalidFormsState(false);
      this.errorSummaryRef = this.findFocusElement("error")!;
      this.errorSummaryRef?.current?.focus();
    }
    this.globalState.submit();
  };

  /**
   * Cleans error state in all forms
   */
  public cleanErrorState = () => {
    if (this.errorInputRef) {
      this.errorInputRef = null;
    }
    this.numberOfErrors = 0;
    this.isFlightCarFormValid = true;
    this.isFlightHotelCarFormValid = true;
    this.isFlightHotelDefaultFormValid = true;
    this.isFlightHotelMultiFormValid = true;
    this.isFlightHotelOneWayFormValid = true;
    this.isHotelCarFormValid = true;
  };

  public updateInvalidFormsState = (validState?: boolean) => {
    const state = validState !== undefined ? validState : true;
    switch (this.subLOBState) {
      case PackagesSubLobs.FLIGHT_CAR:
        this.isFlightCarFormValid = state;
        this.isFlightHotelCarFormValid = true;
        this.updateInvalidFormStateFlightHotel(true);
        this.isHotelCarFormValid = true;
        break;
      case PackagesSubLobs.FLIGHT_HOTEL_CAR:
        this.isFlightCarFormValid = true;
        this.isFlightHotelCarFormValid = state;
        this.updateInvalidFormStateFlightHotel(true);
        this.isHotelCarFormValid = true;
        break;
      case PackagesSubLobs.FLIGHT_HOTEL:
        this.isFlightCarFormValid = true;
        this.isFlightHotelCarFormValid = true;
        this.isHotelCarFormValid = true;
        this.updateInvalidFormStateFlightHotel(state);
        break;
      case PackagesSubLobs.HOTEL_CAR:
        this.isFlightCarFormValid = true;
        this.isFlightHotelCarFormValid = true;
        this.updateInvalidFormStateFlightHotel(true);
        this.isHotelCarFormValid = state;
        break;
      default:
        this.isFlightCarFormValid = true;
        this.isFlightHotelCarFormValid = true;
        this.updateInvalidFormStateFlightHotel(state);
        this.isHotelCarFormValid = true;
        break;
    }
  };

  public updateInvalidFormStateFlightHotel = (formState: boolean) => {
    switch (this.subLOBFlightTypeState) {
      case FlightType.ONE_WAY:
        this.isFlightHotelOneWayFormValid = formState;
        this.isFlightHotelMultiFormValid = true;
        this.isFlightHotelDefaultFormValid = true;
        break;
      case FlightType.MULTI_CITY:
        this.isFlightHotelOneWayFormValid = true;
        this.isFlightHotelMultiFormValid = formState;
        this.isFlightHotelDefaultFormValid = true;

        break;
      default:
        this.isFlightHotelDefaultFormValid = formState;
        this.isFlightHotelOneWayFormValid = true;
        this.isFlightHotelMultiFormValid = true;
        break;
    }
  };

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  public updatePackageDatesFromConfig = () => {
    this.packageDates = this.globalState.getDateFromConfig(this.config.date);
  };

  public updatePartialDatesFromConfig = () => {
    this.partialStayDate = this.globalState.getDateFromConfig(this.config.date);
  };

  public updateEssAdapterConfigPackageType(subLOBState: string) {
    this.config.location.origin.essAdapterConfig.packageType = subLOBState;
    this.config.location.destination.essAdapterConfig.packageType = subLOBState;
  }

  constructor(config: PackageConfig, globalState: GlobalWizardState) {
    super();
    makeObservable(this, {
      config: observable,
      staySomewhereElse: observable,
      driveSomewhereElse: observable,
      partialStay: observable,
      onewayFlight: observable,
      experimentNewPackageOnewayFlightType: observable,
      errorInputRef: observable,
      errorSummaryRef: observable,
      numberOfErrors: observable,
      isHotelCarFormValid: observable,
      isFlightCarFormValid: observable,
      isFlightHotelCarFormValid: observable,
      isFlightHotelDefaultFormValid: observable,
      isFlightHotelOneWayFormValid: observable,
      isFlightHotelMultiFormValid: observable,
      subLOBState: observable,
      subLOBFlightTypeState: observable,
      listOfAdditionalLegs: observable,
      multiCityFlightLegURL: observable,
      partialStayDate: observable,
      packageDates: observable,
      trackingEnabled: observable,
      packageTravelers: observable,
      originInvalidKey: observable,
      destinationInvalidKey: observable,
      hotelTravelersInvalidKey: observable,
      nonHotelTravelersInvalidKey: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      partialStayStartDateInvalidKey: observable,
      partialStayEndDateInvalidKey: observable,
      legsDateStartInvalidKey: observable,
      hotelDestinationInvalidKey: observable,
      location: computed,
      hotelLocation: computed,
      travelersValueChanged: computed,
      flightClassCode: computed,
      flightAirline: computed,
      flightTypeCode: computed,
      date: computed,
      travelers: computed,
      hotelPackageTravelersMetadata: computed,
      nonHotelTravelersMetadata: computed,
      cruiseTravelersMetadata: computed,
      flightTypeCodeForURL: computed,
      buildMultiCityFlightLegUrl: action,
      updateLegLocationOrigin: action,
      updateLegLocationDestination: action,
      updateLegLocation: action,
      swapOriginAndDestinationLocations: action,
      updateLegDate: action,
      setTravelersValue: action,
      moreThanOneError: action,
      findFocusElement: action,
      validateOriginField: action,
      validateDestinationField: action,
      validateDestinationFieldHasValue: action,
      validateOriginDifferentFromDestination: action,
      validateLessThanNTravelers: action,
      validateUnattendedInfantInLap: action,
      validateInfantsPerTraveler: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      validateTravelersField: action,
      validateMaxDateRange: action,
      validateEndDateField: action,
      validateEndDateBeforeStartDateFields: action,
      validatePartialStayStartDateWithinRange: action,
      validatePartialStayStartDateField: action,
      validatePartialStayMaxDateRange: action,
      validatePartialStayEndDateWithinRange: action,
      validateLobCombinationState: action,
      validateDepatureDates: action,
      validateHotelDestination: action,
      resetValidations: action,
      overrideConfig: action,
      updatePackageDates: action,
      updateDateSelection: action,
      updatePartialStayDateSelection: action,
      updateOriginSelection: action,
      updateDestinationSelection: action,
      updateHotelDestinationSelection: action,
      copyDepartureReturningDatesToHotel: action,
      swapLocations: action,
      togglePartialStay: action,
      setFlightClass: action,
      setFlightAirline: action,
      setFlightType: action,
      addALeg: action,
      removeALeg: action,
      updateSubLOBState: action,
      updateSubLOBFlightTypeState: action,
      updateHotelTravelersSelection: action,
      updateNonHotelTravelersSelection: action,
      updateDestinationFromContext: action,
      updateOnewayFlag: action,
      updateExperimentNewPackageVariant: action,
      cleanErrorState: action,
      updateInvalidFormsState: action,
      updateInvalidFormStateFlightHotel: action,
      updateDestinationOnRegionType: action,
      updateDestination: action,
    });
    this.config = config;
    this.globalState = globalState;
    this.packageTravelers = travelersStateOnConfig(this.config.travelers);
    this.packageDates = this.globalState.getDateFromConfig(this.config.date);
    this.partialStayDate = this.globalState.getDateFromConfig(this.config.date);

    this.listOfAdditionalLegs = [];
    const maxNumberOfAdditionalLegs = this.config.multiCityFlight.maxNumberOfAdditionalLegs;
    this.legsDateStartInvalidKey = new Array<string>(maxNumberOfAdditionalLegs).fill("", 0, maxNumberOfAdditionalLegs);
    this.multiCityFlightLegURL = new Array<string>(maxNumberOfAdditionalLegs);
  }
}
