import { action, computed, observable, makeObservable } from "mobx";

import { DeviceInformation } from "bernie-context/dist/platform/device-information";
import { TypeaheadSelection } from "@egds/react-core/typeahead";

import { ClassCodes } from "components/shared/PreferredClassInput/PreferredClassInput";
import { InfantSeating } from "components/shared/TravelersField";
import { PreferredAirline } from "components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { Travelers, TravelersMetadata } from "components/shared/TravelersField/typings";
import { TypeaheadSelectionProps } from "components/flexComponents/WizardHotelPWA/typings";
import { travelersMetadata } from "components/shared/TravelersField/utils";
import { DateState, HotelLocationState, LocationState, TravelersState } from "stores/wizard/state/global";
import { GlobalConfig } from "stores/wizard/config/staticConfigs/global";
import { DateFieldConfig, TravelersFieldConfig } from "stores/wizard/config";
import { dateState } from "./date";
import { hotelLocationState, locationState } from "./location";
import { travelersState } from "./travelers";
import { FlightType } from "../typings";
import { CURRENT_LOCATION_TYPE } from "components/utility/GeolocationUtil";
import { Room } from "src/components/shared/TravelersField/typings";

export class GlobalWizardState {
  public formSubmitted = false;
  public travelersValueChanged = false;
  public datesValueChanged = false;

  get isFormSubmitted() {
    return this.formSubmitted;
  }

  set isFormSubmitted(submitted: boolean) {
    this.formSubmitted = submitted;
  }

  public location: LocationState;
  public hotelLocation: HotelLocationState;
  public config: GlobalConfig;
  public canTrack?: boolean;
  public isDesktop = false;
  public navigation: {
    defaultLob: string;
    activeLob: string;
  };
  public useDefaultLOB = true;
  public date: DateState;
  public travelers: TravelersState;

  //initialize to economy
  public flightClassCode: ClassCodes = ClassCodes.ECONOMY;
  public flightAirline: PreferredAirline;
  public flightTypeCode: FlightType = FlightType.ROUND_TRIP;

  public get hotelTravelersMetadata() {
    return travelersMetadata(this.travelers.hotel);
  }

  public get hotelPackageTravelersMetadata() {
    return travelersMetadata(this.travelers.hotelPackage);
  }

  public get nonHotelTravelersMetadata() {
    return travelersMetadata(this.travelers.nonHotel);
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  public get defaultLob() {
    return this.navigation.defaultLob;
  }

  public get activeLob() {
    return this.navigation.activeLob;
  }

  public moreThanOneError = (numberOfErrors: number) => {
    return numberOfErrors > 1;
  };

  public setIsDesktop(deviceInformation?: DeviceInformation) {
    //default to false if device information is not available
    this.isDesktop = deviceInformation ? !(deviceInformation.tablet || deviceInformation.mobile) : false;
  }

  public setActiveLob(activeLob: string) {
    this.navigation.activeLob = activeLob;
  }

  public updateDateSelection = (start: Date, end: Date) => {
    this.date.start = start;
    this.date.end = end;
    this.datesValueChanged = true;
  };

  public updateOriginSelection(selection: TypeaheadSelection) {
    this.updateCommonLocationSelection("origin", selection);
  }

  public updateDestinationSelection(selection: TypeaheadSelection) {
    this.updateCommonLocationSelection("destination", selection);
    this.location.destination.metaData.hotelId = (selection as TypeaheadSelectionProps).data?.selected;
  }

  private updateCommonLocationSelection(which: "origin" | "destination", selection: TypeaheadSelection) {
    const { term, data } = selection as TypeaheadSelectionProps;

    this.location[which].value = term;
    this.location[which].metaData.destinationId = data?.cityId || data?.regionId;
    this.location[which].metaData.regionId = data?.regionId || data?.cityId || data?.hierarchyInfo?.airport?.airportId;
    this.location[which].metaData.ttla = data?.hierarchyInfo?.airport?.airportCode;
    this.location[which].metaData.countryCode = data?.hierarchyInfo?.country?.isoCode2;
    this.location[which].metaData.shortName = data?.regionNames?.shortName;
    this.location[which].metaData.latLong =
      data?.location?.lat && data?.location?.long ? `${data.location.lat},${data.location.long}` : "";
    this.location[which].metaData.isCurrentLocation = data?.type === CURRENT_LOCATION_TYPE;
  }

  public clearCurrentLocation() {
    if (this.location.destination.metaData.isCurrentLocation) {
      this.location.destination.value = "";
      this.location.destination.metaData.latLong = "";
      this.location.destination.metaData.isCurrentLocation = false;
    }
    if (this.location.origin.metaData.isCurrentLocation) {
      this.location.origin.value = "";
      this.location.origin.metaData.latLong = "";
      this.location.origin.metaData.isCurrentLocation = false;
    }
  }

  public updateHotelDestinationSelection(selection: TypeaheadSelection) {
    const { term, data } = selection as TypeaheadSelectionProps;
    this.hotelLocation.destination.value = term;
    this.hotelLocation.destination.metaData.regionId = data?.regionId || data?.selected;
  }

  public swapOriginAndDestinationLocations() {
    const tempLocation = this.location.origin;
    this.location.origin = this.location.destination;
    this.location.destination = tempLocation;
  }

  public updateOriginFromContext(value: string, regionID: string, countryCode: string, latLong: string, ttla: string) {
    this.location.origin.value = value;
    this.location.origin.metaData.regionId = regionID;
    this.location.origin.metaData.ttla = ttla;
    this.location.origin.metaData.countryCode = countryCode;
    this.location.origin.metaData.latLong = latLong;
  }

  public updateDestinationFromContext(
    value: string,
    regionID: string,
    countryCode: string,
    latLong: string,
    ttla: string,
    destinationID: string,
    hotelID: string
  ) {
    this.location.destination.value = value;
    this.location.destination.metaData.regionId = regionID;
    this.location.destination.metaData.ttla = ttla;
    this.location.destination.metaData.countryCode = countryCode;
    this.location.destination.metaData.latLong = latLong;
    this.location.destination.metaData.destinationId = destinationID;
    this.location.destination.metaData.hotelId = hotelID;
  }

  public updateHotelDestinationFromCookie(
    value: string,
    regionID: string,
    countryCode: string,
    latLong: string,
    ttla: string
  ) {
    this.hotelLocation.destination.value = value;
    this.hotelLocation.destination.metaData.regionId = regionID;
    this.hotelLocation.destination.metaData.ttla = ttla;
    this.hotelLocation.destination.metaData.countryCode = countryCode;
    this.hotelLocation.destination.metaData.latLong = latLong;
  }

  public updateHotelTravelersSelection = (travelers: Travelers, travelersLobState?: TravelersState) => {
    const travelersInstance = travelersLobState ?? this.travelers;

    travelersInstance.nonHotel.rooms = [travelers.rooms[0]];
    travelersInstance.hotel.rooms = travelers.rooms;
    travelersInstance.hotelPackage.rooms = travelers.rooms;
    travelersInstance.cruise.rooms = [travelers.rooms[0]];

    travelersInstance.nonHotel.infantSeating = travelers.infantSeating;
    travelersInstance.hotel.infantSeating = travelers.infantSeating;
    travelersInstance.hotelPackage.infantSeating = travelers.infantSeating;
    travelersInstance.cruise.infantSeating = travelers.infantSeating;
    this.setTravelersValue();
  };

  public updateNonHotelTravelersSelection = (travelers: Travelers, travelersLobState?: TravelersState) => {
    const travelersInstance = travelersLobState ?? this.travelers;

    travelersInstance.nonHotel.rooms = [travelers.rooms[0]];
    travelersInstance.hotel.rooms[0] = travelers.rooms[0];
    travelersInstance.hotelPackage.rooms[0] = travelers.rooms[0];
    travelersInstance.cruise.rooms = [travelers.rooms[0]];

    travelersInstance.nonHotel.infantSeating = travelers.infantSeating;
    travelersInstance.hotel.infantSeating = travelers.infantSeating;
    travelersInstance.hotelPackage.infantSeating = travelers.infantSeating;
    travelersInstance.cruise.infantSeating = travelers.infantSeating;
    this.setTravelersValue();
  };

  public getNumbOfChildrenWithoutAge(rooms: [Room] | Room[]) {
    return rooms.reduce((accum: boolean[], currentValue) => {
      currentValue.children.forEach((child) => {
        if (child.age === -1) {
          accum.push(true);
        }
      });
      return accum;
    }, []).length;
  }

  public getNumbOfInfantsWithoutAge(rooms: [Room] | Room[]) {
    return rooms.reduce((accum: boolean[], currentValue) => {
      currentValue.infants.forEach((infant) => {
        if (infant.age === -1) {
          accum.push(true);
        }
      });
      return accum;
    }, []).length;
  }

  public validateChildrenFields = (travelersLobState?: TravelersState) => {
    const travelersInstance = travelersLobState ?? this.travelers;
    let childrenWithNoValueSelected: number = 0;

    if (travelersInstance.cruise.rooms.length && !Boolean(childrenWithNoValueSelected)) {
      childrenWithNoValueSelected = this.getNumbOfChildrenWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    if (travelersInstance.hotel.rooms.length && !Boolean(childrenWithNoValueSelected)) {
      childrenWithNoValueSelected = this.getNumbOfChildrenWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    if (travelersInstance.hotelPackage.rooms.length && !Boolean(childrenWithNoValueSelected)) {
      childrenWithNoValueSelected = this.getNumbOfChildrenWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    if (travelersInstance.nonHotel.rooms.length && !Boolean(childrenWithNoValueSelected)) {
      childrenWithNoValueSelected = this.getNumbOfChildrenWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    return childrenWithNoValueSelected;
  };

  public validateInfantFields = (travelersLobState?: TravelersState) => {
    const travelersInstance = travelersLobState ?? this.travelers;
    let infantWithNoValueSelected: number = 0;

    if (travelersInstance.cruise.rooms.length && !Boolean(infantWithNoValueSelected)) {
      infantWithNoValueSelected = this.getNumbOfInfantsWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    if (travelersInstance.hotel.rooms.length && !Boolean(infantWithNoValueSelected)) {
      infantWithNoValueSelected = this.getNumbOfInfantsWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    if (travelersInstance.hotelPackage.rooms.length && !Boolean(infantWithNoValueSelected)) {
      infantWithNoValueSelected = this.getNumbOfInfantsWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    if (travelersInstance.nonHotel.rooms.length && !Boolean(infantWithNoValueSelected)) {
      infantWithNoValueSelected = this.getNumbOfInfantsWithoutAge(travelersInstance.hotelPackage.rooms);
    }

    return infantWithNoValueSelected;
  };

  public setFlightClass(flightClassCode: ClassCodes) {
    this.flightClassCode = flightClassCode;
  }

  public setFlightAirline(flightAirline: PreferredAirline) {
    this.flightAirline = flightAirline;
  }

  public setFlightType(flightTypeCode: FlightType) {
    this.flightTypeCode = flightTypeCode;
  }

  public setTravelersValue = () => {
    this.travelersValueChanged = true;
  };

  public validateOriginField = (): boolean => !!this.location.origin.value;

  public validateDestinationField = (): boolean => !!this.location.destination.value;

  public submit() {
    this.formSubmitted = true;
  }

  public validateOriginDifferentFromDestination = (): boolean =>
    this.location.origin.value !== this.location.destination.value;

  public validateLessThanNTravelers = (
    { numOfAdults, numOfChildren, numOfInfants }: TravelersMetadata,
    { withInfants, minBookableTravelers, maxBookableTravelers }: TravelersFieldConfig
  ): boolean => {
    numOfAdults = numOfAdults === 0 ? 1 : numOfAdults;
    const numOfTravelers = numOfAdults + numOfChildren + (withInfants ? numOfInfants : 0);

    return numOfTravelers >= minBookableTravelers && numOfTravelers <= maxBookableTravelers;
  };

  public validateUnattendedInfantInLap = (
    { travelersAgeTwelveOrOlder, numOfInfants }: TravelersMetadata,
    { withInfants }: TravelersFieldConfig,
    infantSeating: InfantSeating
  ): boolean => {
    if (!withInfants || infantSeating === InfantSeating.IN_SEAT) {
      return true;
    }

    return travelersAgeTwelveOrOlder >= numOfInfants;
  };

  public validateInfantsPerTraveler = (
    { travelersAgeTwelveOrOlder, numOfInfants }: TravelersMetadata,
    { withInfants, minInfantPerTraveler }: TravelersFieldConfig,
    infantSeating: InfantSeating
  ): boolean => {
    if (!withInfants || infantSeating === InfantSeating.ON_LAP) {
      return true;
    }

    return travelersAgeTwelveOrOlder * minInfantPerTraveler! >= numOfInfants;
  };

  public validatePartialDateWithinRange = (rangeStart: Date, rangeEnd: Date, date: Date): boolean => {
    const minValidDate = new Date(rangeStart.getFullYear(), rangeStart.getMonth(), rangeStart.getDate());
    const maxValidDate = new Date(rangeEnd.getFullYear(), rangeEnd.getMonth(), rangeEnd.getDate(), 23, 59, 59, 999);

    return date >= minValidDate && date <= maxValidDate;
  };

  public validateMaxDateRange = (startDate: Date, endDate: Date, maxDaysRange: number): boolean => {
    const maxEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + maxDaysRange + 1);

    return endDate < maxEndDate;
  };

  public validateEndDateBeforeStartDateFields = (startDate: Date, endDate: Date) => startDate > endDate;

  public updateDateFromConfig = (dateConfig: DateFieldConfig) => {
    if (!this.datesValueChanged) {
      this.date = this.getDateFromConfig(dateConfig);
    }
  };

  public getDateFromConfig = (dateConfig: DateFieldConfig): DateState => {
    const { defaultOffsetDates } = dateConfig;

    if (defaultOffsetDates) {
      const { start, end } = defaultOffsetDates;

      return dateState(end, start);
    }

    return dateState();
  };

  constructor(config: GlobalConfig, canTrack?: boolean) {
    this.config = config;
    makeObservable(this, {
      formSubmitted: observable,
      travelersValueChanged: observable,
      datesValueChanged: observable,
      location: observable,
      hotelLocation: observable,
      config: observable,
      canTrack: observable,
      isDesktop: observable,
      navigation: observable,
      date: observable,
      travelers: observable,
      flightClassCode: observable,
      flightAirline: observable,
      flightTypeCode: observable,
      moreThanOneError: action,
      setIsDesktop: action,
      setActiveLob: action,
      updateDateSelection: action,
      updateOriginSelection: action,
      updateDestinationSelection: action,
      clearCurrentLocation: action,
      updateHotelDestinationSelection: action,
      swapOriginAndDestinationLocations: action,
      updateHotelTravelersSelection: action,
      updateNonHotelTravelersSelection: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      setFlightClass: action,
      setFlightAirline: action,
      setFlightType: action,
      setTravelersValue: action,
      validateOriginField: action,
      validateDestinationField: action,
      submit: action,
      isFormSubmitted: computed,
      hotelTravelersMetadata: computed,
      hotelPackageTravelersMetadata: computed,
      nonHotelTravelersMetadata: computed,
      cruiseTravelersMetadata: computed,
      defaultLob: computed,
      activeLob: computed,
    });
    this.navigation = config.navigation;
    this.location = locationState();
    this.hotelLocation = hotelLocationState();
    this.date = dateState();
    this.travelers = travelersState;
    this.canTrack = canTrack;
  }
}
