import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { action, computed, observable, makeObservable } from "mobx";
import { CarConfig } from "stores/wizard/config";
import { TypeaheadLocationType, TypeaheadSelection } from "@egds/react-core/typeahead";
import * as React from "react";
import { Travelers } from "src/components/shared/TravelersField/typings";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { DateState } from "../global/date/typings";
import { TypeaheadSelectionProps } from "src/components/flexComponents/WizardHotelPWA/typings";
import { CarSubLOBs } from "../typings";
import { GroundTransportationLocationState } from "./GroundTransportationLocationState";
import { locationState } from "../global/location/locationState";
import { LocationState } from "stores/wizard/state/global/location";
import { travelersStateOnConfig } from "../global/travelers/travelersState";
import { TravelersState } from "stores/wizard/state/global";
import { WizardValidationState } from "../validations/WizardValidationState";
import { ValidationData, ValidationState } from "../validations/typings";
import { getCurrentLocationSelection } from "components/utility/GeolocationUtil";

export class CarWizardState implements LOBState {
  private globalState: GlobalWizardState;

  public carsLocation: LocationState;

  public get location() {
    // Always map global destination as cars origin
    this.carsLocation.origin = this.globalState.location.destination;

    return this.carsLocation;
  }

  public carType = "";

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  public config: CarConfig;
  public isDifferentDropOff = false;
  public isGeolocationAllowed = true;

  public toggleIsDifferentDropOff() {
    const { pickUpDropOffLabelToken, pickUpLocationLabelToken } = this.config.location.origin;

    this.isDifferentDropOff = !this.isDifferentDropOff;
    this.config.location.origin.labelToken = this.isDifferentDropOff
      ? pickUpLocationLabelToken
      : pickUpDropOffLabelToken;
  }

  public setIsDifferentDropoff(differentDropoff: boolean) {
    const { pickUpDropOffLabelToken, pickUpLocationLabelToken } = this.config.location.origin;

    this.isDifferentDropOff = differentDropoff;
    this.config.location.origin.labelToken = this.isDifferentDropOff
      ? pickUpLocationLabelToken
      : pickUpDropOffLabelToken;
  }

  public preferredBrand = "";
  public pickupTime: string;
  private canonicalMinutesDefault = 630;
  public pickupTimeCanonicalMinutes: number = this.canonicalMinutesDefault; //"1030AM"
  public dropOffTimeCanonicalMinutes: number = this.canonicalMinutesDefault; //"1030AM"
  public returnTime: string;
  public isPayWithPointsChecked = false;

  public togglePayWithPointsChecked = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isPayWithPointsChecked = stateToForce ?? !this.isPayWithPointsChecked;
  };

  public setDefaultShowingTime = () => {
    if (!this.pickupTime) {
      this.pickupTime = this.config.defaultShowingTime;
    }
    if (!this.returnTime) {
      this.returnTime = this.config.defaultShowingTime;
    }
  };

  public subLOBState: CarSubLOBs = CarSubLOBs.RENTAL;

  public updateSubLOBState(subLOBState: CarSubLOBs) {
    this.subLOBState = subLOBState;
  }

  public isGtSubLOBState = () => this.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION;

  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];

  public driversAgeInputRef: React.RefObject<HTMLInputElement>;

  public carCouponCodeType = "noCode";
  public couponCode0: string;
  public couponCode1: string;
  public couponCode2: string;
  public driversAge = "30";
  public validations: ValidationState;
  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public errorSummaryRef: React.RefObject<HTMLInputElement> | null;
  public isCarFormValid = true;
  public isGroundTransportFormValid = true;
  public numberOfErrors = 0;

  public get date() {
    return this.globalState.date;
  }

  // Ground Transportation
  public additionalSingleDate: DateState;
  public roundTrip = false;
  public nonHotelTravelersInvalidKey: string | "";

  public toggleBookRoundtrip() {
    this.roundTrip = !this.roundTrip;
  }

  public updateAdditionalSingleDate = (start: Date, end: Date) => {
    this.additionalSingleDate.start = start;
    this.additionalSingleDate.end = end;
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.timeStartInvalidKey = "";
    this.timeEndInvalidKey = "";
  };

  public updateNonHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateNonHotelTravelersSelection(travelers, this.travelers);

    this.nonHotelTravelersInvalidKey = "";
  };

  findFocusElement = (keyword: string) =>
    this.wizardInputsArray.find((element) => element.current !== null && element.current.outerHTML.includes(keyword));

  public validateLessThanNTravelers = () => {
    if (!this.globalState.validateLessThanNTravelers(this.nonHotelTravelersMetadata, this.config.travelers)) {
      this.nonHotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

      return false;
    }

    return true;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (!this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (!this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() && this.validateChildrenFields() && this.validateInfantFields();

  public groundTransportTravelers: TravelersState;

  public get travelers() {
    return this.groundTransportTravelers;
  }

  public get nonHotelTravelersMetadata() {
    return travelersMetadata(this.travelers.nonHotel);
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  //validations
  public originInvalidKey: string | "";
  public destinationInvalidKey: string | "";
  public driversAgeInvalidKey: string | "";

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  public findElementRef = (wizardInputsArray: React.RefObject<HTMLInputElement>[], elementName: string) =>
    this.wizardInputsArray.find(
      (element) => element.current !== null && element.current.outerHTML.includes(elementName)
    );

  public updateDestinationSelectionSameAsOrigin() {
    const { destination, origin } = this.location;
    destination.value = origin.value;
    destination.metaData.regionId = origin.metaData.regionId;
    destination.metaData.ttla = origin.metaData.ttla;
    destination.metaData.countryCode = origin.metaData.countryCode;
    destination.metaData.shortName = origin.metaData.shortName;
    destination.metaData.latLong = origin.metaData.latLong;
    destination.metaData.isCurrentLocation = origin.metaData.isCurrentLocation;
  }

  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";
  public timeStartInvalidKey = "";
  public timeEndInvalidKey = "";

  public resetValidations = () => {
    //gt state
    this.groundTransportationLocationState?.resetValidations();

    //car state
    this.originInvalidKey = "";
    this.destinationInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.timeStartInvalidKey = "";
    this.timeEndInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
    this.driversAgeInvalidKey = "";
  };

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updateDateSelection = (start: Date, end: Date) => {
    this.globalState.updateDateSelection(start, end);
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.timeStartInvalidKey = "";
    this.timeEndInvalidKey = "";
  };

  public dlat = "";
  public dlon = "";

  public updateDestinationSelection = (selection: TypeaheadSelection) => {
    this.dlat = "";
    this.dlon = "";
    const { term, data } = selection as TypeaheadSelectionProps;
    const currLoc = data.type === TypeaheadLocationType.CURRENT_LOCATION;
    if (currLoc) {
      this.dlat = data?.location?.lat ?? "";
      this.dlon = data?.location?.long ?? "";
    }
    if (this.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION) {
      this.globalState.updateDestinationSelection(selection);
    } else {
      this.location.destination.value = term;
      this.location.destination.metaData.destinationId = data?.cityId || data?.regionId;
      this.location.destination.metaData.regionId = data?.regionId;
      this.location.destination.metaData.ttla = data?.hierarchyInfo?.airport?.airportCode;
      this.location.destination.metaData.countryCode = data?.hierarchyInfo?.country?.isoCode2;
      this.location.destination.metaData.shortName = data?.regionNames?.shortName;
      this.location.destination.metaData.hotelId = data?.selected;
      this.location.destination.metaData.latLong =
        data?.location?.lat && data?.location?.long ? `${data.location.lat},${data.location.long}` : "";
      this.location.destination.metaData.isCurrentLocation = currLoc;

      // if region id is not present we can send the lat long of the selected location
      if (!data?.regionId) {
        this.dlat = data?.location?.lat ?? "";
        this.dlon = data?.location?.long ?? "";
      }
    }
    this.destinationInvalidKey = "";
  };

  public clearCurrentLocation() {
    this.olat = "";
    this.olon = "";
    this.dlat = "";
    this.dlon = "";
    if (this.location.origin.metaData.isCurrentLocation) {
      this.location.origin.value = "";
      this.location.origin.metaData.latLong = "";
      this.location.origin.metaData.isCurrentLocation = false;
    }
    if (this.location.destination.metaData.isCurrentLocation) {
      this.location.destination.value = "";
      this.location.destination.metaData.latLong = "";
      this.location.destination.metaData.isCurrentLocation = false;
    }
  }

  public updateCouponCodeType = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    const carCouponCodeType = selectEvent.target.value;
    this.carCouponCodeType = carCouponCodeType;
    this.couponCode0 = "";
    this.couponCode1 = "";
    this.couponCode2 = "";
  };

  public updateCouponCodeTypeWithoutSelect = (val: string) => {
    const carCouponCodeType = val;
    this.carCouponCodeType = carCouponCodeType;
    this.couponCode0 = "";
    this.couponCode1 = "";
    this.couponCode2 = "";
  };

  public updateCouponCode = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;
    if (index === 0) {
      this.couponCode0 = code;
    } else if (index === 1) {
      this.couponCode1 = code;
    } else if (index === 2) {
      this.couponCode2 = code;
    }
  };

  public updateDriversAgeInput = (event: React.ChangeEvent<HTMLInputElement>) => (this.driversAge = event.target.value);

  /* istanbul ignore next */
  public updateHotelRegionId = (regionId: number) => {
    // @ts-ignore
    this.config.gtConfig.location?.destination?.essAdapterConfig!.regionId = regionId;
  };

  public olat = "";
  public olon = "";

  public updateOriginSelection = (selection: TypeaheadSelection) => {
    this.originInvalidKey = "";
    this.olat = "";
    this.olon = "";
    const { data } = selection as TypeaheadSelectionProps;
    if (data.type === TypeaheadLocationType.CURRENT_LOCATION) {
      this.olat = data?.location?.lat ?? "";
      this.olon = data?.location?.long ?? "";
    }
    if (this.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION) {
      this.globalState.updateOriginSelection(selection);
      this.updateHotelRegionId(parseInt(data?.regionId, 10));
    } else {
      // Since car pick-up maps to global destination
      this.globalState.updateDestinationSelection(selection);

      // if region id is not present we can send the lat long of the selected location
      if (!data?.regionId) {
        this.olat = data?.location?.lat ?? "";
        this.olon = data?.location?.long ?? "";
      }
    }
  };

  public setPreferredBrand = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    this.preferredBrand = selectEvent.target.value;
    this.couponCode0 = "";
    this.couponCode1 = "";
    this.couponCode2 = "";
  };

  public setPreferredBrandWithoutSelect = (val: string) => {
    this.preferredBrand = val;
    this.couponCode0 = "";
    this.couponCode1 = "";
    this.couponCode2 = "";
  };

  public setPickupTime = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    this.pickupTime = selectEvent.target.value;
    const canonicalMinutes =
      selectEvent.target && selectEvent.target.selectedOptions
        ? selectEvent.target.selectedOptions[0].dataset["time"]
        : "";
    this.pickupTimeCanonicalMinutes = canonicalMinutes ? parseInt(canonicalMinutes, 10) : this.canonicalMinutesDefault;
    this.timeStartInvalidKey = "";
  };

  public setReturnTime = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    this.returnTime = selectEvent.target.value;
    this.timeEndInvalidKey = "";
    const canonicalMinutes =
      selectEvent.target && selectEvent.target.selectedOptions
        ? selectEvent.target.selectedOptions[0].dataset["time"]
        : "";
    this.dropOffTimeCanonicalMinutes = canonicalMinutes ? parseInt(canonicalMinutes, 10) : this.canonicalMinutesDefault;
  };

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  public updateGroundTransfersFieldsFromQueryParams = (queryParams: URLSearchParams) => {
    const pickUpDateString = queryParams.get("pickUpDate");
    const dropOffDateString = queryParams.get("dropOffDate");

    if (pickUpDateString) {
      const pickUpDate = new Date(pickUpDateString);
      this.updateDateSelection(pickUpDate, pickUpDate);
    }
    if (this.roundTrip && dropOffDateString) {
      const dropOffDate = new Date(dropOffDateString);
      this.updateAdditionalSingleDate(dropOffDate, dropOffDate);
    }
    let room = this.groundTransportTravelers.nonHotel.rooms[0];
    if (queryParams.get("adults")) {
      room.adults = Number(queryParams.get("adults"));
    }
    const childrenCount = Number(queryParams.get("children"));
    if (childrenCount) {
      const ageValue = this.config.gtConfig.travelers?.minChildAge ?? -1;
      room.children = room.children.concat(
        Array(childrenCount).fill({
          age: ageValue,
        })
      );
    }
    const infantCount = Number(queryParams.get("infants"));
    if (infantCount) {
      const ageValue = this.config.gtConfig.travelers?.minInfantAge ?? -1;
      room.infants = room.infants.concat(
        Array(infantCount).fill({
          age: ageValue,
        })
      );
    }
    this.groundTransportTravelers.nonHotel.rooms[0] = room;
  };

  public updateStateValidations = (validationsData: any) => {
    this.updateLocErrorKeys(validationsData.invalidKeys);
    this.errorInputRef = validationsData.inputReference;
    this.numberOfErrors = validationsData.numberOfErrors;
  };

  public updateLocErrorKeys = (invalidKeys: any) => {
    if (this.isGtSubLOBState()) {
      this.groundTransportationLocationState.originInvalidKey = invalidKeys.origin;
      this.groundTransportationLocationState.destinationInvalidKey = invalidKeys.destination;
    } else {
      this.originInvalidKey = invalidKeys.origin;
      this.destinationInvalidKey = invalidKeys.destination;
    }

    this.dateEndInvalidKey = invalidKeys.dateEnd;
    this.timeStartInvalidKey = invalidKeys.startTime;
    this.timeEndInvalidKey = invalidKeys.endTime;
    this.driversAgeInvalidKey = invalidKeys.driversAge;
  };

  public getGtDataToValidate = () => {
    const { origin, destination } = this.groundTransportationLocationState.location;
    const { startTime, endTime } = this.config.validations;
    const { start } = this.date;

    const pickupTimeValidationsData = startTime ? { canonicalMinutes: this.pickupTimeCanonicalMinutes } : null;
    const endDate = this.roundTrip ? this.additionalSingleDate.start : null;

    const dropOffTimeValidationsData =
      this.roundTrip && endTime ? { canonicalMinutes: this.dropOffTimeCanonicalMinutes } : null;

    const gtDataToValidate = {
      location: {
        origin: origin.value,
        destination: destination.value,
      },
      dates: {
        start,
        end: endDate,
      },
      time: {
        pickup: pickupTimeValidationsData,
        dropoff: dropOffTimeValidationsData,
      },
      references: this.wizardInputsArray,
    };

    return gtDataToValidate;
  };

  public getCarsDataToValidate = () => {
    const { origin } = this.location;
    const { start, end } = this.date;
    const { startTime } = this.config.validations;
    const pickupTimeValidationsData = startTime ? { canonicalMinutes: this.pickupTimeCanonicalMinutes } : null;

    const carsDataToValidate: ValidationData = {
      location: {
        origin: origin.value,
        destination: null,
      },
      dates: {
        start,
        end,
      },
      time: {
        pickup: pickupTimeValidationsData,
        dropoff: null,
      },
      inputs: {
        cars: {
          driversAge: this.driversAge,
        },
      },
      references: this.wizardInputsArray,
    };

    return carsDataToValidate;
  };

  private submitWithUserLocation = (event: React.FormEvent) => {
    event.preventDefault();

    getCurrentLocationSelection()
      .then((selection) => {
        this.updateOriginSelection(selection);
        (document.getElementById(this.config.elementId!) as HTMLFormElement)?.submit();
      })
      .catch(() => {
        this.isGeolocationAllowed = false;
        this.postSubmit();
      });
  };

  private postSubmit = (event?: React.FormEvent) => {
    const isGTTabSelected = this.isGtSubLOBState();
    const config = isGTTabSelected ? this.config.gtConfig : this.config;
    const dataToValidate: ValidationData = isGTTabSelected ? this.getGtDataToValidate() : this.getCarsDataToValidate();

    const { isValid, data } = this.validations.validateForm(dataToValidate, config);

    if (!isValid) {
      this.updateStateValidations(data);
      event?.preventDefault();
      this.updateInvalidFormsState(false);
      this.validations.focusErrorSummary();
    }
  };

  public submit = (event: React.FormEvent) => {
    if (this.config.location.origin.defaultToUserLocation && !this.location.origin.value) {
      return this.submitWithUserLocation(event);
    }

    this.postSubmit(event);
  };

  /**
   * Cleans error state in all forms
   */

  public cleanErrorState = () => {
    if (this.errorInputRef) {
      this.errorInputRef = null;
    }
    this.numberOfErrors = 0;
    this.isCarFormValid = true;
    this.isGroundTransportFormValid = true;
  };

  public resetSubLobState = () => {
    this.updateSubLOBState(CarSubLOBs.RENTAL);
  };

  public resetSubLobTabs = () => {
    this.updateSubLOBState(CarSubLOBs.RENTAL);
  };

  //Resets other cars forms to not have errors when you change to a different car sublob type
  //Shows error message in the right cars type
  public updateInvalidFormsState = (validState?: boolean) => {
    const state = validState !== undefined ? validState : true;
    switch (this.subLOBState) {
      case CarSubLOBs.GROUND_TRANSPORTATION:
        this.isGroundTransportFormValid = state;
        this.isCarFormValid = true;
        break;
      default:
        this.isCarFormValid = state;
        this.isGroundTransportFormValid = true;
        break;
    }
  };

  //Gt form v2
  public groundTransportationLocationState: GroundTransportationLocationState;

  constructor(config: CarConfig, globalState: GlobalWizardState) {
    makeObservable(this, {
      carsLocation: observable,
      carType: observable,
      config: observable,
      isDifferentDropOff: observable,
      isGeolocationAllowed: observable,
      preferredBrand: observable,
      pickupTime: observable,
      returnTime: observable,
      subLOBState: observable,
      carCouponCodeType: observable,
      couponCode0: observable,
      couponCode1: observable,
      couponCode2: observable,
      driversAge: observable,
      errorInputRef: observable,
      errorSummaryRef: observable,
      isCarFormValid: observable,
      isGroundTransportFormValid: observable,
      numberOfErrors: observable,
      additionalSingleDate: observable,
      roundTrip: observable,
      nonHotelTravelersInvalidKey: observable,
      groundTransportTravelers: observable,
      originInvalidKey: observable,
      destinationInvalidKey: observable,
      driversAgeInvalidKey: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      timeStartInvalidKey: observable,
      timeEndInvalidKey: observable,
      dlat: observable,
      dlon: observable,
      olat: observable,
      olon: observable,
      groundTransportationLocationState: observable,
      location: computed,
      travelersValueChanged: computed,
      date: computed,
      travelers: computed,
      nonHotelTravelersMetadata: computed,
      cruiseTravelersMetadata: computed,
      setTravelersValue: action,
      setDefaultShowingTime: action,
      updateSubLOBState: action,
      updateAdditionalSingleDate: action,
      updateNonHotelTravelersSelection: action,
      findFocusElement: action,
      validateLessThanNTravelers: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      validateTravelersField: action,
      moreThanOneError: action,
      updateDestinationSelectionSameAsOrigin: action,
      resetValidations: action,
      overrideConfig: action,
      updateDateSelection: action,
      updateDestinationSelection: action,
      clearCurrentLocation: action,
      updateCouponCodeType: action,
      updateCouponCodeTypeWithoutSelect: action,
      updateCouponCode: action,
      updateDriversAgeInput: action,
      updateOriginSelection: action,
      setPreferredBrand: action,
      setPreferredBrandWithoutSelect: action,
      setPickupTime: action,
      setReturnTime: action,
      cleanErrorState: action,
      resetSubLobState: action,
      resetSubLobTabs: action,
      updateInvalidFormsState: action,
      isPayWithPointsChecked: observable,
    });

    this.config = config;
    this.globalState = globalState;
    this.groundTransportationLocationState = new GroundTransportationLocationState(config.gtConfig, locationState());
    this.groundTransportTravelers = travelersStateOnConfig(this.config.travelers);
    this.carsLocation = locationState();
    this.additionalSingleDate = this.globalState.getDateFromConfig(config.date);
    this.validations = new WizardValidationState();
    this.setDefaultShowingTime();
  }
}

export interface ToggleCheckbox {
  stateToForce?: boolean;
}
