import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { action, computed, observable, makeObservable } from "mobx";
import { VrConfig } from "stores/wizard/config";
import { TypeaheadSelection } from "@egds/react-core/typeahead";
import { Child, Room, Travelers } from "src/components/shared/TravelersField/typings";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { ToggleCheckbox } from "stores/wizard/state/hotel/HotelWizardState";

export class VrWizardState implements LOBState {
  private globalState: GlobalWizardState;

  public wizardInput: React.RefObject<HTMLInputElement>;

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  public get location() {
    return this.globalState.location;
  }

  public get travelers() {
    const nonHotelRoom: [Room] = [
      {
        adults: 2,
        children: [],
        infants: [],
      },
    ];

    // Loop through existing room Info for travelers to find applicable room
    const applicableRoomsForTravelers: Room[] = this.globalState.travelers.hotel.rooms.map(
      (room: Room, roomIndex: number) => {
        const applicableAdultsPerRoom =
          room.adults > this.config.travelers.maxAdultsPerRoom ? this.config.travelers.maxAdultsPerRoom : room.adults;

        const combinedChildren = room.children.concat(room.infants);
        const applicableChildrenPerRoom =
          combinedChildren.length > this.config.travelers.maxChildrenPerRoom
            ? combinedChildren.splice(0, this.config.travelers.maxChildrenPerRoom)
            : combinedChildren;

        return {
          adults: applicableAdultsPerRoom,
          children: applicableChildrenPerRoom,
          infants: [],
        };
      }
    );

    // Only the first room details to be preserved in nonHotel [Room]
    nonHotelRoom[0] = applicableRoomsForTravelers[0];

    const vrTravelers = {
      hotel: {
        rooms: applicableRoomsForTravelers,
        infantSeating: this.globalState.travelers.hotel.infantSeating,
      },
      hotelPackage: {
        rooms: applicableRoomsForTravelers,
        infantSeating: this.globalState.travelers.hotelPackage.infantSeating,
      },
      nonHotel: {
        rooms: nonHotelRoom,
        infantSeating: this.globalState.travelers.nonHotel.infantSeating,
      },
      cruise: {
        rooms: nonHotelRoom,
        infantSeating: this.globalState.travelers.nonHotel.infantSeating,
      },
    };

    return vrTravelers;
  }

  public get hotelTravelersMetadata() {
    return travelersMetadata(this.travelers.hotel);
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  public config: VrConfig;
  public subLOBState = "";

  //Since this LOB doesn't have a subLOB, setting this as empty so the queryParam is removed when navigating to this LOB
  public updateSubLOBState(subLOBState: string) {
    this.subLOBState = "";
  }

  public get date() {
    return this.globalState.date;
  }

  // validations
  public destinationInvalidKey: string | "";

  public validateDestinationField = () => {
    if (!this.globalState.validateDestinationField()) {
      this.destinationInvalidKey = this.config.location.destination.invalidLocationMessageKey;
      this.wizardInput?.current?.focus();

      return false;
    }

    this.destinationInvalidKey = "";

    return true;
  };

  public hotelTravelersInvalidKey = "";

  public validateLessThanNTravelers = () => {
    const isValid = this.globalState.validateLessThanNTravelers(this.hotelTravelersMetadata, this.config.travelers);
    if (!isValid) {
      this.hotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

      return false;
    }

    return true;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() && this.validateChildrenFields() && this.validateInfantFields();

  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";

  public validateMaxDateRange = () => {
    if (!this.globalState.validateMaxDateRange(this.date.start, this.date.end, this.config.date.maxDaysRange)) {
      this.dateEndInvalidKey = this.config.date.invalidMaxDatesRangeMessageToken;

      return false;
    }

    return true;
  };

  public validateEndDateField = () => this.validateMaxDateRange();

  public resetValidations = () => {
    this.destinationInvalidKey = "";
    this.hotelTravelersInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
  };

  public validateForm = () => {
    const validations: boolean[] = [
      this.validateDestinationField(),
      this.validateTravelersField(),
      this.validateEndDateField(),
    ];

    return !validations.includes(false);
  };

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updateDateSelection = (start: Date, end: Date) => {
    this.globalState.updateDateSelection(start, end);
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
  };

  public updateDestinationSelection = (selection: TypeaheadSelection) => {
    this.globalState.updateDestinationSelection(selection);
    this.destinationInvalidKey = "";
  };

  public submit = (event: React.FormEvent) => {
    const isValidForm = this.validateForm();
    if (!isValidForm) {
      event.preventDefault();
    }
  };

  public updateHotelTravelersSelection = (travelers: Travelers) => {
    const rooms = travelers.rooms.map((room) => {
      const [roomInfants, roomChildren] = room.children.reduce(
        ([infants, children]: [Child[], Child[]], child) => {
          child.age < 2 ? infants.push(child) : children.push(child);

          return [infants, children];
        },
        [[], []]
      );

      return {
        adults: room.adults,
        children: roomChildren,
        infants: roomInfants,
      };
    });
    this.globalState.updateHotelTravelersSelection({
      rooms,
      infantSeating: travelers.infantSeating,
    });
    this.hotelTravelersInvalidKey = "";
  };

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  public isPayWithPointsChecked = false;

  public togglePayWithPointsChecked = ({ stateToForce }: ToggleCheckbox = {}) => {
    this.isPayWithPointsChecked = stateToForce ?? !this.isPayWithPointsChecked;
  };

  constructor(config: VrConfig, globalState: GlobalWizardState) {
    makeObservable(this, {
      config: observable,
      subLOBState: observable,
      destinationInvalidKey: observable,
      hotelTravelersInvalidKey: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      travelersValueChanged: computed,
      location: computed,
      travelers: computed,
      hotelTravelersMetadata: computed,
      cruiseTravelersMetadata: computed,
      date: computed,
      setTravelersValue: action,
      updateSubLOBState: action,
      validateDestinationField: action,
      validateLessThanNTravelers: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      validateTravelersField: action,
      validateMaxDateRange: action,
      validateEndDateField: action,
      resetValidations: action,
      overrideConfig: action,
      updateDateSelection: action,
      updateDestinationSelection: action,
      updateHotelTravelersSelection: action,
      isPayWithPointsChecked: observable,
    });

    this.config = config;
    this.globalState = globalState;
  }
}
